
import {
  ReactNode,
  createContext,
  useContext,
  useReducer,
} from "react";
import { EventInterface } from "../Interfaces/Event";
import { EventReducer } from "../reducers/EventReducer";

interface EventDataContextValue {
  eventState: EventInterface;
  eventDispatch: any;
}

const EventDataContext = createContext<EventDataContextValue | undefined>(
  undefined
);

interface EventDataProviderProps {
  children: ReactNode;
}

export function EventDataProvider({ children }: EventDataProviderProps) {
  const initialEventState: EventInterface = {
    eventName: "",
    startDate: "",
    startTime: "10",
    endDate: "",
    endTime: "12",
    details: "",
    location: "",
    form: {
      title: "",
      description: "",
      sections: [],
    },
    subevents: [],
    eventBanner:"",
    eventId:"",
    defaultMail:"<p>Hello guys</p>"
  };

  const [eventState, eventDispatch] = useReducer(
    EventReducer,
    initialEventState
  );

  const eventDataContextValue: EventDataContextValue = {
    eventState,
    eventDispatch,
  };

  return (
    <EventDataContext.Provider value={eventDataContextValue}>
      {children}
    </EventDataContext.Provider>
  );
}


//hook
export function useEventDataContext() {
  const context = useContext(EventDataContext);
  if (!context) {
    throw new Error(
      "useEventDataContext must be used within a FormDataProvider"
    );
  }
  return context;
}
