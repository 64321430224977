import { EventFormData, FormField, FormSection } from "../Interfaces/Form";

type FormAction =
  | { type: "SET_TITLE"; payload: string }
  | {
      type: "SET_SECTION_NAME";
      payload: { sectionId: string; sectionName: string };
    }
  | { type: "SET_DESCRIPTION"; payload: string }
  | { type: "ADD_SECTION"; payload: FormSection }
  | { type: "REMOVE_SECTION"; payload: string }
  | { type: "ADD_FIELD"; payload: { sectionId: string; field: FormField } }
  | { type: "REMOVE_FIELD"; payload: { sectionId: string; fieldId: string } }
  | {
      type: "UPDATE_FIELD";
      payload: { sectionId: string; fieldId: string; updatedField: FormField };
    }
  | {
      type: "UPDATE_FORM_ID";
      payload: string;
    }
  | {
      type: "UPDATE_ALL_FIELDS";
      payload: { sectionId: string; updatedFields: FormField[] };
    };

const formReducer = (
  state: EventFormData,
  action: FormAction
): EventFormData => {
  switch (action.type) {
    case "SET_TITLE":
      return {
        ...state,
        title: action.payload,
      };
    case "SET_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };
    case "SET_SECTION_NAME":
      return {
        ...state,
        sections: state.sections.map((section) =>
          section.sectionId === action.payload.sectionId
            ? {
                ...section,
                sectionName: action.payload.sectionName,
              }
            : section
        ),
      };
    case "ADD_SECTION":
      return {
        ...state,
        sections: [...state.sections, action.payload],
      };
    case "REMOVE_SECTION":
      return {
        ...state,
        sections: state.sections.filter(
          (section) => section.sectionId !== action.payload
        ),
      };
    case "ADD_FIELD":
      return {
        ...state,
        sections: state.sections.map((section) =>
          section.sectionId === action.payload.sectionId
            ? {
                ...section,
                fields: [...section.fields, action.payload.field],
              }
            : section
        ),
      };
    case "REMOVE_FIELD":
      return {
        ...state,
        sections: state.sections.map((section) =>
          section.sectionId === action.payload.sectionId
            ? {
                ...section,
                fields: section.fields.filter(
                  (field) => field.id !== action.payload.fieldId
                ),
              }
            : section
        ),
      };
    case "UPDATE_FIELD":
      return {
        ...state,
        sections: state.sections.map((section) =>
          section.sectionId === action.payload.sectionId
            ? {
                ...section,
                fields: section.fields.map((field) =>
                  field.id === action.payload.fieldId
                    ? action.payload.updatedField
                    : field
                ),
              }
            : section
        ),
      };
    case "UPDATE_ALL_FIELDS":
      return {
        ...state,
        sections: state.sections.map((section) =>
          section.sectionId === action.payload.sectionId
            ? {
                ...section,
                fields: action.payload.updatedFields,
              }
            : section
        ),
      };
    case "UPDATE_FORM_ID":
      return {
        ...state,
        formId: action.payload,
      };

    default:
      return state;
  }
};

export default formReducer;
