import { Spin } from "antd";
import "./Loader.css";

type Props = {
  loading: boolean;
  onRoute?: boolean;
};

function Loader({ loading, onRoute }: Props) {
  return (
    <>
      {loading ? (
        <div
          className={`loader-container backdrop-blur-[.5px] w-full ${
            onRoute ? " h-screen" : "h-full"
          }`}
        >
          <Spin className="" size="large" />
        </div>
      ) : null}
    </>
  );
}

export default Loader;
