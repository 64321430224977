import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom"; // Import BrowserRouter
import App from "./App";
import "./index.css";
import ScrollToTop from "./utils/ScrollToTop";
import ThemeConfigProvider from "./themeConfig";

ReactDOM.render(
  <React.StrictMode>
    <ThemeConfigProvider>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </ThemeConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
