import { EventInterface, SubEventInterface } from "../Interfaces/Event";
import { EventFormData } from "../Interfaces/Form";

type FormAction = { type: "UPDATE_FORM"; payload: EventFormData };
type STATEACTION = { type: "UPDATE_STATE"; payload: any };
type SubEventAction = {
  type: "UPDATE_SUBEVENT_INFO";
  payload: SubEventInterface;
};
type EventInfoAction = {
  type: "UPDATE_EVENT_INFO";
  payload: SubEventInterface;
};

export const EventReducer = (
  state: EventInterface ,
  action: EventInfoAction | SubEventAction | FormAction | STATEACTION
): EventInterface => {
  switch (action.type) {
    case "UPDATE_EVENT_INFO":
      return { ...state, ...action.payload };
    case "UPDATE_SUBEVENT_INFO":
      return { ...state, subevents: [...state.subevents || [], action.payload] };
    case "UPDATE_FORM":
      return {
        ...state,
        form: action.payload,
      };
    case "UPDATE_STATE":
      return { ...action.payload };
    default:
      return state;
  }
};





