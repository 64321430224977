 
import React, { createContext, useContext, ReactNode, useReducer } from "react";
import { EventFormData } from "../Interfaces/Form";
import formReducer from "../reducers/FormReducer";

interface FormDataContextValue {
  formData: EventFormData;
  formDispatch: React.Dispatch<any>;
}

const FormDataContext = createContext<FormDataContextValue | undefined>(
  undefined
);

interface FormDataProviderProps {
  children: ReactNode;
}

const initialFormData = {
  title: "",
  description: "",
  sections: [
    {
      sectionId: "1",
      sectionName:"Section 1",
      fields: [
        {
          id: "1",
          label: "",
          type: "text",
          isRequired: false,
        },
      ],
    },
  ],
};
export function FormDataProvider({ children }: FormDataProviderProps) {
  const [formData, formDispatch] = useReducer(formReducer, initialFormData);

  const contextValue: FormDataContextValue = {
    formData,
    formDispatch,
  };

  return (
    <FormDataContext.Provider value={contextValue}>
      {children}
    </FormDataContext.Provider>
  );
}

export function useFormDataContext() {
  const context = useContext(FormDataContext);
  if (!context) {
    throw new Error(
      "useFormDataContext must be used within a FormDataProvider"
    );
  }
  return context;
}
