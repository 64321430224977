import { EventInterface } from "../Interfaces/Event";
import EventsApi from "../apis/eventsApi";
import { API_STATUS, TOAST_TYPE } from "../constants/AppConstants";
import { CommonUtils } from "../utils/CommonUtils";

const createEvent = async (data: EventInterface) => {
  const { body, message, status } = await EventsApi().createEvent(data);
  if (status === API_STATUS.SUCCESS) {
    CommonUtils().showToast(TOAST_TYPE.SUCCESS, "Event Created Successfully");
    return body;
  } else {
    CommonUtils().showToast(
      TOAST_TYPE.SUCCESS,
      "Something went wrong, Please try again"
    );
    return body;
  }
};

const fetchAllEvents = async () => {
  const data = await EventsApi().fetchEvents();
  return data;
};

const fetchEventById = async (id: string) => {
  const data = await EventsApi().fetchEventById(id);
  return data;
  // if (status === API_STATUS.SUCCESS) return body;
  // else {
  //   CommonUtils().showToast(
  //     TOAST_TYPE.SUCCESS,
  //     "Something went wrong, Please try again"
  //   );
  //   throw new Error();
  // }
};

const fetchEventFormById = async (id: string, signal: AbortSignal) => {
  const data = await EventsApi().fetchEventFormById(id, signal);
  return data;
};

const deleteEvent = async (id: string) => {
  const data = await EventsApi().deleteEvent(id);
  return data;
};

const fetchOrganizerEvents = async () => {
  const data = await EventsApi().fetchOrganizerEvents();
  return data;
};

const fetchUserEvents = async () => {
  const data = await EventsApi().fetchUserEvents();
  return data;
};

const fetchRegisterdStatus = async (eventId: string, email: string) => {
  const data = await EventsApi().fetchRegisterdStatus(eventId, email);
  return data;
};

const fetchSubEventsSummary = async (eventId: string) => {
  const data = await EventsApi().fetchSubEventsSummary(eventId);
  return data;
};

const getEventGroups = async (eventId:string) => {
  const data = await EventsApi().getEventGroups(eventId);
  return data;
};

const groupParticipants = async (eventId:string, groupData:any) => {
  const data = await EventsApi().groupParticipants(eventId,groupData);
  return data;
};

const createGroup = async (eventId:string, groupName:string) => {
  const data = await EventsApi().createGroup(eventId,groupName);
  return data;
};

const fetchAnnouncements = async (eventId:string) => {
  const data = await EventsApi().fetchAnnouncements(eventId);
  return data;
};

export function EventService() {
  return {
    createEvent,
    fetchAllEvents,
    deleteEvent,
    fetchEventById,
    fetchEventFormById,
    fetchOrganizerEvents,
    fetchUserEvents,
    fetchRegisterdStatus,
    fetchSubEventsSummary,
    getEventGroups,
    groupParticipants,
    createGroup,
    fetchAnnouncements
  };
}
