import { ConfigProvider } from "antd";

const ThemeConfigProvider = ({children}:any) => (
  <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: '#ff5c00',
        borderRadius: 2,

        // Alias Token
        // colorBgContainer: '#ff5c00',
      },
      
    }}
  >{children}</ConfigProvider>
);

export default ThemeConfigProvider;
