import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { validatePhoneNumber } from "../../utils/globalFunctions";

function CustomPhoneInput({ style, onChange }: any) {
  const [value, setValue] = useState<string>("");

  const handleChange = (inputValue: string, country: any) => {
    setValue(`+${inputValue.toString()}`);
    if (onChange) {
      onChange(`+${inputValue.toString()}`);
    }
  };

  return (
    <PhoneInput
      inputStyle={{ ...style, width: "100%" }}
      placeholder="Enter phone number"
      enableSearch={true}
      country={"bd"}
      autoFormat
      countryCodeEditable={false}
      disableSearchIcon
      value={value}
      onChange={handleChange}
      isValid={(inputValue) => {
        if (inputValue.length > 3) {
          return validatePhoneNumber(`+${inputValue}`);
        }
        return true;
      }}
    />
  );
}

export default CustomPhoneInput;
