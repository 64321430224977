export const TOAST_TYPE = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",
  WARNING:"warning"
});
export const API_STATUS = Object.freeze({
  SUCCESS: 201,
  ERROR: "error",
});
