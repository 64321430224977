const PlatformUrl = process.env.REACT_APP_Platform_Root_URL || "";

export const EventEndPoints = {
  createEvent: PlatformUrl + "/events",
  getEvents: PlatformUrl + "/events",
  getEventsById: PlatformUrl + "/events/{slug}",
  deleteEvent: PlatformUrl + "/events/{id}",
  getEventFormById: PlatformUrl + "/event/{id}/forms",
  getOrganizerEvents: PlatformUrl + "/organizers/events",
  getUserEvents: PlatformUrl + "/participants/myevents",
  getRegisterdStatus: PlatformUrl + "/event/{eventId}/participants/{email}",
  subEventsSummary: PlatformUrl + "/event/{eventId}/summary",
  getEventGroups: PlatformUrl + "/event/{eventId}/groups",
  groupParticipants: PlatformUrl + "/event/{eventId}/participants/groups",
  createGroup: PlatformUrl + "/event/{eventId}/groups",
  getSecondPhase: PlatformUrl + "/v2/event/{eventId}/participants/{participantId}",
  fetchAnnouncements: PlatformUrl + "/events/{eventId}/announcements",
};

