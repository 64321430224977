import { PhoneNumberUtil } from "google-libphonenumber";
import { EventService } from "../service/EventService";
import { handleApiError } from "./ApiErrorHandler";
const phoneUtil = PhoneNumberUtil.getInstance();

export const validatePhoneNumber = (phoneNumber:string) => {
  try {
    // Parse the phone number
    const parsedNumber = phoneUtil.parse(phoneNumber);
    // Check if the parsed number is valid
    const isValid = phoneUtil.isValidNumber(parsedNumber);
    return isValid;
  } catch (e) {
    // Handle any parsing errors
    return false;
  }
}

export const debounce = (fn: any, delay: any) => {
  let timeoutId: any = null;
  return (...args: any) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const getGroups = async (eventId:string) => {
  try {
    const groups = await EventService().getEventGroups(eventId);
    // setGrouplist(groups);
    return groups;
  } catch (error) {
    handleApiError(error, ()=>{});
  }
};

