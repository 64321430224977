import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC9PPU4WBxtom4NcsW-6n210HCktvNfmy0",
  authDomain: "evento-aa1f1.firebaseapp.com",
  projectId: "evento-aa1f1",
  storageBucket: "evento-aa1f1.appspot.com",
  messagingSenderId: "398720945621",
  appId: "1:398720945621:web:c77ff359e9a5abaab5015c",
  measurementId: "G-3HCNYLB85S",
};

const app = initializeApp(firebaseConfig);

export default app;
