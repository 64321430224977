import { GoogleOAuthProvider } from "@react-oauth/google";
import { EventDataProvider } from "./context/EventContext";
import { UserContextProvider } from "./context/UserContext";
import GoogleOneTapLogin from "./feature/auth/components/GoogleOneTapLogin";
import ToastComponent from "./components/Common/ToastComponent";
import { MobileScreenProvider } from "./context/MobileScreenProvider";
import { FormDataProvider } from "./context/FormContext";
import { Suspense, lazy } from "react";
import Loader from "./components/Common/Loader";
const AppOutlets = lazy(() => import("./AppOutlets"));

function App() {
  return (
    <div className="max-w-screen relative  max-h-screen">
      <UserContextProvider>
        <EventDataProvider>
          <FormDataProvider>
            <Suspense fallback={<Loader onRoute={true} loading={true} />}>
              <MobileScreenProvider>
                <GoogleOAuthProvider
                  clientId={`${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}
                >
                  <ToastComponent />
                  <GoogleOneTapLogin />
                  <AppOutlets />
                </GoogleOAuthProvider>
              </MobileScreenProvider>
            </Suspense>
          </FormDataProvider>
        </EventDataProvider>
      </UserContextProvider>
    </div>
  );
}

export default App;
