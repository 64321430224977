import userApi from "../apis/userApi";

const fetchUserProfile = async () => {
    const response = await userApi().fetchUserProfile();
    return response;
  }

  const updateProfile = async (params:any,updatedUser:any) => {
    const response = await userApi().updateProfile(params,updatedUser);
    return response;
  }

  export function UserService() {
    return {
        fetchUserProfile,
        updateProfile,
    };
  }