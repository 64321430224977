import { useGoogleOneTapLogin } from '@react-oauth/google';
import { useUserContext } from '../../../../context/UserContext';

type Props = {
  onSuccess:(credentialResponse: any)=>void
  onError:()=>void
}

function GoogleOneTapLoginHelper({onSuccess,onError}: Props) {
    const { checkUserLoggedIn, signOutUser } = useUserContext();
    useGoogleOneTapLogin({
        onSuccess: onSuccess,
       onError:onError
      }) 
  return (
    <div style={{marginTop: ''}}></div>
  )
}

export default GoogleOneTapLoginHelper