import React, { useState, createContext, useContext, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/auth";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

type User = {
  userName?: string;
  email: string;
  picture?: string;
  phoneNumber?: string;
};

type UserContextType = {
  loggedInUser: User | null;
  setLoggedInUser: React.Dispatch<React.SetStateAction<User | null>>;
  authToken: string | null;
  setAuthToken: React.Dispatch<React.SetStateAction<string | null>>;
  checkUserLoggedIn: any;
  getLoggedInUser: any;
  signOutUser: any;
};

type Props = { children: React.ReactNode };

export const UserContext = createContext<UserContextType>({
  loggedInUser: null,
  setLoggedInUser: () => {},
  authToken: null,
  setAuthToken: () => {},
  checkUserLoggedIn: () => {},
  getLoggedInUser: () => {},
  signOutUser: () => {},
});

export function UserContextProvider(props: Props) {
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    checkUserLoggedIn();
  }, [user]);

  const processToken = (token: string) => {
    const decodedToken: any = jwt_decode(token);
    const userDetails = {
      userName: decodedToken.name || "",
      email: decodedToken.email || "",
      picture: decodedToken.picture || "",
      phoneNumber: decodedToken.phoneNumber || "+8801847214965",
    };
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
    setLoggedInUser(userDetails);
  };

  const checkUserLoggedIn = () => {
    const storedAuthToken = localStorage.getItem("authToken");
    const storedUserDetails = localStorage.getItem("userDetails");

    if (storedAuthToken) {
      setAuthToken(storedAuthToken);
    }

    if (storedUserDetails) {
      const userDetails = JSON.parse(storedUserDetails);
      setLoggedInUser({
        userName: userDetails.name || "",
        email: userDetails.email || "",
        picture: userDetails.picture || "",
        phoneNumber: userDetails.phoneNumber || "",
      });
    }
  };

  const signOutUser = async () => {
    await signOut(auth);
    localStorage.removeItem("authToken");
    localStorage.removeItem("userDetails");
    setLoggedInUser(null);
    setAuthToken(null);
    navigate("/login");
  };

  const getLoggedInUser = () => {
    if (loggedInUser) {
      return loggedInUser;
    } else {
      const storedUserDetails = localStorage.getItem("userDetails");

      if (storedUserDetails) {
        try {
          const userDetails: User = JSON.parse(storedUserDetails);
          setLoggedInUser(userDetails);
          return userDetails;
        } catch (error) {
          console.error(
            "Error parsing user details from local storage:",
            error
          );
        }
      }
    }
  };

  return (
    <UserContext.Provider
      value={{
        loggedInUser,
        setLoggedInUser,
        authToken,
        setAuthToken,
        checkUserLoggedIn,
        getLoggedInUser,
        signOutUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
// hook
export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
}
