import React, { useState, createContext, useContext, useEffect } from "react";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useUserContext } from "../../../context/UserContext";
import GoogleOneTapLoginHelper from "./utils/GoogleOneTapLoginHelper";
import GoogleAuth from "./GoogleAuth";

type Props = {};

function GoogleOneTapLogin({}: Props) {
  const { loggedInUser } = useUserContext();

  // Conditionally execute the useGoogleOneTapLogin hook

  // Render the component only when user is null
  if (loggedInUser === null) {
    return (
      <div>
        <GoogleAuth type="onetap" />
      </div>
    );
  }

  return null; // Return null when user is not null
}

export default GoogleOneTapLogin;
