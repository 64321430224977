import { UserEndPoints } from "../endpoints/UserEndPoints";
import { CommonUtils } from "../utils/CommonUtils";
import securedAxiosInstance from "../utils/secureAxiosInstance";

const { buildUrl } = CommonUtils();

const fetchUserProfile = async () => {
  const url = UserEndPoints.getUserProfile;
  const response = await securedAxiosInstance.get(buildUrl(url, null, ""));

  return response;
};

const updateProfile = async (params: any, updatedUser: any) => {
  console.log("hello", params);
  const url = UserEndPoints.updateProfile;
  const response = await securedAxiosInstance.put(
    buildUrl(url, null, params),
    updatedUser
  );
  return response;
};

function userApi() {
  return {
    fetchUserProfile,
    updateProfile,
  };
}

export default userApi;
