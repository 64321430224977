import { Form } from 'antd';
import React from 'react'
import { validatePhoneNumber } from '../../../utils/globalFunctions';
import CustomPhoneInput from '../../../components/Common/CustomPhoneInput';

type Props = {}

const PhoneInput = (props: Props) => {
  return (
    <Form.Item
          name={["user", "phoneNumber"]}
          label={
            <span className="text-[15px] font-semibold font-Poppins">
              Phone Number
            </span>
          }
         
          rules={[
            {
              required: true,
              message: "",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (validatePhoneNumber(value) ) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Invalid phone number"));
              },
            }),
          ]}
        >
          <CustomPhoneInput style={{ width: "100%" }} />
        </Form.Item>
  )
}

export default PhoneInput