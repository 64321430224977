type Props = {
  onClick?: any;
  text: string;
  disabled?: boolean;
};

const NavButton = (props: Props) => {
  const { onClick, text, disabled } = props;
  return (
    <button
      type="submit"
      disabled={disabled}
      className={`inline-block  ${
        disabled
          ? "bg-red-700 bg-opacity-70 opacity-75"
          : "bg-orange-500 hover:bg-[#FF5C00]hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] hover:bg-orange-600 focus:bg-orange-[#EE2722] focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-[#EE2722] active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)"
      }  rounded px-3 lg:px-4 pb-2 pt-2 lg:pt-2.5 text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out   ] 
      tracking-wide`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default NavButton;
