import { GoogleLogin } from "@react-oauth/google";
import { Form, Modal } from "antd";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavButton from "../../../components/Common/NavButton";
import { TOAST_TYPE } from "../../../constants/AppConstants";
import { useUserContext } from "../../../context/UserContext";
import { UserService } from "../../../service/userService";
import { handleApiError } from "../../../utils/ApiErrorHandler";
import { CommonUtils } from "../../../utils/CommonUtils";
import PhoneInput from "./PhoneInput";
import GoogleOneTapLoginHelper from "./utils/GoogleOneTapLoginHelper";

type Props = {
  type?: string;
};

const GoogleAuth = ({ type }: Props) => {
  const [user, setUser] = useState<any>();
  const [form] = Form.useForm();

  const [showPhnModal, setShowPhnModal] = useState(false);
  const { getLoggedInUser, signOutUser } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const requestedRoute = localStorage.getItem("requestedRoute");

  const backToPreviousPath = () => {
    if (requestedRoute && getLoggedInUser()) {
      navigate(requestedRoute);
      localStorage.removeItem("requestedRoute");
    } else if (location.pathname === "/register") {
      navigate("/events");
    } else {
      const search =
        location.search && location.search !== "?null" ? location.search : "";
      navigate(`${location.pathname}${search}`);
    }
  };
  const googleLogin = async (credentialResponse: any) => {
    const googleTokenData: any = jwt_decode(credentialResponse.credential);
    localStorage.setItem("authToken", credentialResponse.credential);

    try {
      const { data } = await UserService().fetchUserProfile();
      setUser(data);

      if (data.phoneNumber) {
        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            userName: data.userName || "",
            email: data.email || "",
            picture: data.picture || googleTokenData.picture || "",
            phoneNumber: data.phoneNumber,
          })
        );
        CommonUtils().showToast(
          TOAST_TYPE.SUCCESS,
          `Successfully Logged in as ${data.userName}`
        );

        backToPreviousPath();
      } else {
        setShowPhnModal(true);
        CommonUtils().showToast(
          TOAST_TYPE.SUCCESS,
          `Add your phone number to complete your profile. Thanks!`
        );
      }
    } catch (error: any) {
      handleApiError(error, signOutUser);
    } finally {
    }
  };

  const updatePhoneNumber = async (phone: any) => {
    try {
      if (user) {
        const res = await UserService().updateProfile(
          {
            userId: user._id,
          },
          phone
        );

        res &&
          localStorage.setItem(
            "userDetails",
            JSON.stringify({
              userName: user.name || "",
              email: user.email || "",
              picture: user.picture || "",
              phoneNumber: phone.phoneNumber,
            })
          );
      }

      setShowPhnModal(false);

      CommonUtils().showToast(
        TOAST_TYPE.SUCCESS,
        "Phone number added successfully!"
      );

      backToPreviousPath();
    } catch (error) {
      cathchError();
    }
  };

  const cathchError = () =>
    CommonUtils().showToast(
      TOAST_TYPE.ERROR,
      "Something Wrong! Please try again"
    );

  const handlePhnSubmit = (values: any) => {
    const phone = { phoneNumber: values.user.phoneNumber };
    updatePhoneNumber(phone);
  };

  const handleCancel = () => {
    setShowPhnModal(false);
    CommonUtils().showToast(
      TOAST_TYPE.ERROR,
      "We need your phone number to unlock full experience!"
    );
  };
  return (
    <div className="flex items-center  justify-center gap-5 rounded-lg shadow-md font-medium active:bg-gray-200 transition-transform duration-300">
      {type === "onetap" ? (
        <GoogleOneTapLoginHelper
          onSuccess={googleLogin}
          onError={cathchError}
        />
      ) : (
        <GoogleLogin onSuccess={googleLogin} onError={cathchError} />
      )}

      <Modal
        open={showPhnModal}
        onCancel={handleCancel}
        footer={[
          <div className="flex w-full justify-between">
            <p className="text-neutral-500 text-sm text-left">
              *Your privacy is our priority
            </p>
            <NavButton text="Submit" onClick={() => form.submit()} />
          </div>,
        ]}
        bodyStyle={{
          margin: "0px 0px 0px 0px",
          padding: "0px 0px 0px 0px",
        }}
        className=""
      >
        <Form
          form={form}
          onFinish={handlePhnSubmit}
          className="w-full flex pt-5 flex-col items-center gap-1 md:gap-5"
        >
          <h1 className="text-center py-2 font-inter text-slate-800 shadow-lg drop-shadow-md text-base md:text-xl md:p-2 mb-5">
            Please add your Phone number for Event confirmation!
          </h1>
          <div className="md:w-[85%] w-full">
            <PhoneInput />
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default GoogleAuth;
