// MobileScreenProvider.tsx
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

interface MobileScreenContextType {
  isMobile: boolean;
}

const MobileScreenContext = createContext<MobileScreenContextType | undefined>(undefined);

export const useMobileScreen = (): boolean => {
  const context = useContext(MobileScreenContext);
  if (!context) {
    throw new Error('useMobileScreen must be used within a MobileScreenProvider');
  }
  return context.isMobile;
};

interface MobileScreenProviderProps {
  children: ReactNode;
}

export const MobileScreenProvider: React.FC<MobileScreenProviderProps> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    updateIsMobile();

    window.addEventListener('resize', updateIsMobile);

    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  return (
    <MobileScreenContext.Provider value={{ isMobile }}>
      {children}
    </MobileScreenContext.Provider>
  );
};
