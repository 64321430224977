import axios from "axios";

const securedAxiosInstance = axios.create();

securedAxiosInstance.interceptors.request.use((config) => {
  const authToken = localStorage.getItem("authToken");


  config.headers.Authorization = `Bearer ${authToken}`;

  return config;
});

export default securedAxiosInstance;
