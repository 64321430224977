import { TOAST_TYPE } from "../constants/AppConstants";
import { CommonUtils } from "./CommonUtils";

export const handleApiError = (error: any, signOutUser: () => void) => {
  if (error?.response?.status === 498) {
    signOutUser();
    CommonUtils().showToast(
      TOAST_TYPE.ERROR,
      "Session expired. Please log in again."
    );
  } else {
    CommonUtils().showToast(
      TOAST_TYPE.ERROR,
      error?.response?.data?.message || "Server error..."
    );
  }
};
