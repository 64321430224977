import { EventInterface } from "../Interfaces/Event";
import { EventEndPoints } from "../endpoints/EventEndpoints";
import { CommonUtils } from "../utils/CommonUtils";
import securedAxiosInstance from "../utils/secureAxiosInstance";

const { buildUrl } = CommonUtils();
const createEvent = async (data: EventInterface) => {
  const url = EventEndPoints.createEvent;
  const response = await securedAxiosInstance.post(
    buildUrl(url, null, ""),
    data
  );
  return response.data;
};

const fetchEvents = async () => {
  const url = EventEndPoints.getEvents;
  const response = await securedAxiosInstance.get(buildUrl(url, null, ""));
  // console.log(response.data);
  return response.data;
};

const fetchEventById = async (id: string) => {
  const url = EventEndPoints.getEventsById;
  const response = await securedAxiosInstance.get(
    buildUrl(url, null, { slug: id })
  );

  return response.data;
};

const fetchEventFormById = async (id: string, signal: AbortSignal) => {
  const url = EventEndPoints.getEventFormById;
  const response = await securedAxiosInstance.get(
    buildUrl(url, null, { id: id }),
    { signal }
  );

  return response.data;
};

const deleteEvent = async (id: string) => {
  const url = EventEndPoints.getEvents;
  const response = await securedAxiosInstance.delete(
    buildUrl(url, null, { id: id })
  );

  return response.data;
};

const fetchOrganizerEvents = async () => {
  const url = EventEndPoints.getOrganizerEvents;
  const response = await securedAxiosInstance.get(buildUrl(url, null, null));
  return response.data;
};

const fetchUserEvents = async () => {
  const url = EventEndPoints.getUserEvents;
  const response = await securedAxiosInstance.get(buildUrl(url, null, null));
  return response.data;
};

const fetchRegisterdStatus = async (eventId: string, email: string) => {
  const url = EventEndPoints.getRegisterdStatus;

  const response = await securedAxiosInstance.get(
    buildUrl(url, null, { eventId, email })
  );
  return response.data;
};

const fetchSubEventsSummary = async (eventId: string) => {
  const url = EventEndPoints.subEventsSummary;
  const response = await securedAxiosInstance.get(
    buildUrl(url, null, { eventId })
  );
  return response.data;
};

const getEventGroups = async (eventId: string) => {
  const url = EventEndPoints.getEventGroups;
  const response = await securedAxiosInstance.get(
    buildUrl(url, null, { eventId })
  );
  return response.data;
};

const groupParticipants = async (eventId: string, groupData: any) => {
  const url = EventEndPoints.groupParticipants;
  const response = await securedAxiosInstance.post(
    buildUrl(url, null, { eventId }),
    groupData
  );
  return response.data;
};

const createGroup = async (eventId: string, groupName: string) => {
  const url = EventEndPoints.createGroup;
  const response = await securedAxiosInstance.post(
    buildUrl(url, null, { eventId }),
    { groupName }
  );
  return response.data;
};

const fetchAnnouncements = async (eventId: string) => {
  const url = EventEndPoints.fetchAnnouncements;
  const response = await securedAxiosInstance.get(
    buildUrl(url, null, { eventId }),
  );
  return response.data;
};

function EventsApi() {
  return {
    createEvent,
    fetchEvents,
    deleteEvent,
    fetchEventById,
    fetchEventFormById,
    fetchOrganizerEvents,
    fetchUserEvents,
    fetchRegisterdStatus,
    fetchSubEventsSummary,
    getEventGroups,
    groupParticipants,
    createGroup,
    fetchAnnouncements,
  };
}

export default EventsApi;
